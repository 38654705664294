import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui' // 引入element
import 'element-ui/lib/theme-chalk/index.css'; // 引入全局样式, 例如 icon

Vue.config.productionTip = false

Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
